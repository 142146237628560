import Sheet from "@mui/joy/Sheet";
import { CssVarsProvider } from "@mui/joy/styles/CssVarsProvider";
import DeleteAccountHeader from "./deleteAccountHeader";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import TextField from "@mui/joy/TextField";
import { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import backAxios from "../axios/back.axios";
import ModalClose from "@mui/joy/ModalClose";
import Modal from "@mui/joy/Modal";
import { useSearchParams } from "react-router-dom";
import {
  Auth,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { app } from "../firebase";
import User from "../data/model/user";
import CircularProgress from "@mui/joy/CircularProgress";
import CardMedia from "@mui/material/CardMedia";

const AccountDeletion = () => {
  const [email, setEmail] = useState("");
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isSetEmail, setIsSetEmail] = useState(true);
  const [searchParams] = useSearchParams();
  const [isSignInWithLink, setIsSignInWithLink] = useState(true);
  const [isAccountDelete, setIsAccountDelete] = useState(false);

  // single-time read
  const userEmail: any = isSetEmail ? searchParams.get("userEmail") : "";
  useEffect(() => {
    document.title = "Con.tact: Network Connect App";
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onSubmitLogin = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsEmailLoading(true);
    await doLogin();
    setIsApiCall(true);
    setIsEmailLoading(false);
    setEmail("");
  };

  const doLogin = async () => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/deletion/verify/V2/${email}`;
      const response = await backAxios.get(url);
      return response;
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return error;
    }
  };
  const deleteUserProfile = async (uid: string) => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_URL as string
      }/api/moderation/profiles/deletion/delete-profile/${uid}`;
      const response = await backAxios.delete(url);
      const data = response.data as User;
      return data;
    } catch (error) {
      return error;
    }
  };
  const getProfileAndDelete = async () => {
    const auth: Auth = getAuth(app);
    const result = isSignInWithEmailLink(auth, window.location.href);
    if (result) {
      try {
        // eslint-disable-next-line
        const response = await signInWithEmailLink(
          auth,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          userEmail,
          window.location.href
        );
        const idToken = await response.user.getIdToken();
        if (idToken) {
          localStorage.setItem("access_token", idToken);
          const profile: any = await deleteUserProfile(response.user.uid);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (profile.response?.status === 404) {
            setIsSignInWithLink(false);
          } else {
            setIsSetEmail(false);
            setIsAccountDelete(true);
          }
        }
      } catch (error) {
        setIsSignInWithLink(false);
        console.error("Error occurred:", error);
      }
    }
  };
  if (userEmail) {
    // Check if the email exists in Firebase
    void (async () => {
      await getProfileAndDelete();
    })();
  }

  return (
    <>
      {!userEmail && !isApiCall && isSetEmail ? (
        <CssVarsProvider>
          <Sheet
            sx={{
              maxWidth: 400,
              mx: "auto",
              my: 7,
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
            }}
          >
            <div>
              <DeleteAccountHeader />
              {/* <Typography level="body3">
                If you click on “Delete account” all your data will be deleted
                irrevocably. Are you sure you want to leave con.tact? We are
                sorry to see you go!
              </Typography> */}
            </div>
            <form>
              <TextField
                autoComplete="username"
                name="email"
                type="email"
                placeholder="your@email.com"
                label="Email"
                value={email}
                onChange={handleEmailChange}
              />
              <Button
                type="button"
                color="danger"
                sx={{
                  mt: 1,
                }}
                style={{
                  marginRight: 10,
                  // background: 'linear-gradient(135deg, #007BFF, #00FF7F)'
                }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onSubmitLogin}
              >
                {isEmailLoading ? "Deleting..." : "Delete Account"}
              </Button>
            </form>
          </Sheet>
        </CssVarsProvider>
      ) : isApiCall ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={isApiCall}
          onClose={() => setIsApiCall(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Account Deletion Request
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
              An email verification link has been sent to your email address.
              Please check your inbox to proceed with account deletion.
            </Typography>
          </Sheet>
        </Modal>
      ) : !isSignInWithLink ? (
        // isSignInWithLink false when error comes from identitytoolkit from google api()
        <CssVarsProvider>
          <Sheet
            sx={{
              maxWidth: 300,
              mx: "auto",
              my: 7,
              py: 5,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "20%", md: "40%" },
                minWidth: { md: "10%" },
                objectFit: "contain",
                alignItems: "center",
                textAlign: "center",
              }}
              height="70"
              component="img"
              src={"images/cross.png"}
            />
            <Typography level="h4" component="h1">
              <Typography
                level="h4"
                fontSize="sm"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  borderRadius: "50%",
                }}
              >
                There is some issue in delete your account.
              </Typography>
            </Typography>
          </Sheet>
        </CssVarsProvider>
      ) : isAccountDelete ? (
        //when delete account api call
        <CssVarsProvider>
          <Sheet
            sx={{
              maxWidth: 300,
              mx: "auto",
              my: 7,
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "20%", md: "40%" },
                minWidth: { md: "10%" },
                objectFit: "contain",
                alignItems: "center",
                textAlign: "center",
              }}
              height="70"
              component="img"
              src={"images/check.png"}
            />
            <Typography
              level="h4"
              component="h1"
              style={{ textAlign: "center" }}
            >
              <b>Account Deleted Successfully</b>
              <br></br>
              <Typography level="h4" fontSize="sm">
                Your account has been successfully deleted.<br></br> We hope to
                see you again!
              </Typography>
            </Typography>
          </Sheet>
        </CssVarsProvider>
      ) : (
        //when account deleted successfully
        <CssVarsProvider>
          <Sheet
            sx={{
              maxWidth: 300,
              mx: "auto",
              my: 7,
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              level="h4"
              component="h1"
              style={{ textAlign: "center" }}
            >
              <b>Account Deletion in process</b>
              <br></br>
              <Typography level="h4" fontSize="sm">
                Your account deletion is in progress.<br></br> Please wait...
              </Typography>
            </Typography>
            <CircularProgress size="lg" />
          </Sheet>
        </CssVarsProvider>
      )}

      <Box
        sx={{
          maxWidth: 600,
          mx: "auto",
          my: 7,
          flexDirection: "column",
        }}
      >
        <Typography level="body1">
          <b>Steps to follow :</b>
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Enter your email address, for which you want to delete account from{" "}
        </Typography>
        <Typography level="body1">"Con.tact: Network Connect App".</Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Click on "Delete Account" button
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Contact will get back to you through email
        </Typography>
        <Typography level="body1">
          <b>Data that will be deleted :</b> Your all kind of data will be
          deleted permanently from the servers after you choose delete account
          option.
        </Typography>
        <Typography level="body1">The data that we collect includes</Typography>
        <Typography level="body1">
          <b>Personal Info :</b> Name, Email, Phone, and other info
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Location : Data related to your location.
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Media : Photos, Videos, Documents you've sent or received through the
          app.
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Your activity
        </Typography>
        <Typography
          startDecorator={
            <Box
              component="span"
              sx={{
                bgcolor: "black",
                width: "0.5em",
                height: "0.5em",
                borderRadius: "50%",
              }}
            />
          }
        >
          Device ID and Info
        </Typography>
      </Box>
    </>
  );
};

export default AccountDeletion;
